@use 'common';
@import './base/_root';
@import './base/_typography.scss';
@import './base/_normalize.scss';
//@import './base/_overrides.scss';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
}

#root {
  height: 100%;
  overflow: auto;
}

.op-app {
  height: 100%;
}

// Add Graphik font family to everything but the items listed
*:not(i,
  i *,
  .badge-preview,
  .badge-preview *,
  .op-code-editor,
  .op-code-editor *) {
  font-family: 'Graphik', Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
}

// Used in a few places to make sure we have the correct background color
.op-content-container {
  transition: background-color 0.3s;
  background-color: var(--colorBgContainer);
  background: var(--colorBgContainer) !important;
}


#header {
  display: flex;
  background-color: var(--colorHeaderBg);
  height: common.toRem(56);
  align-items: center;
}

#main-content-scrollable {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

.jarviswidget {
  display: inline-block;
  width: 100%;
  font-size: common.toRem(16);
}

.jarviswidget>header {
  height: common.toRem(33);
  padding: 0;
  line-height: common.toRem(32);
  margin-left: common.toRem(11);
}

.form-control[disabled] {
  cursor: default !important;
}

/* START - Terrible attempt at standardization that needs to be removed */
.op-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.op-font-weight-500 {
  font-weight: 500;
}

.op-line-height-normal {
  line-height: normal;
}

/* Positions */
.op-position-relative {
  position: relative;
}

.op-position-absolute {
  position: absolute;
}

/* Margins */
.op-margin-top-11 {
  margin-top: common.toRem(11) !important;
}

.op-margin-top-22 {
  margin-top: common.toRem(22) !important;
}

.op-margin-bottom-11 {
  margin-bottom: common.toRem(11) !important;
}

.op-margin-bottom-22 {
  margin-bottom: common.toRem(22) !important;
}

.op-margin-0 {
  margin: 0 !important;
}

.op-margin-left-11 {
  margin-left: common.toRem(11) !important;
}

.op-margin-right-11 {
  margin-right: common.toRem(11) !important;
}

.op-vertical-margin-large {
  margin: common.toRem(22) 0 !important;
}



/* Alignments */
.op-display-flex {
  display: flex !important;
}

.op-flex-direction-column {
  display: flex;
  flex-direction: column;
}

.op-fully-centered-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.op-justify-content-flex-start {
  display: flex;
  justify-content: flex-start;
}

.op-justify-content-center {
  display: flex;
  justify-content: center;
}

.op-justify-content-space-between {
  display: flex;
  justify-content: space-between;
}

.op-justify-content-flex-end {
  display: flex;
  justify-content: flex-end;
}

.op-flex-1 {
  display: flex;
  flex: 1;
}

.op-align-items-center {
  display: flex !important;
  align-items: center !important;
}

.op-cover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.op-padding-0 {
  padding: 0 !important;
}

.op-padding-bottom-0 {
  padding-bottom: 0 !important;
}

.op-horizontal-padding-11 {
  padding: 0 common.toRem(11);
}

.op-overflow-hidden {
  overflow: hidden;
}

.op-height-auto {
  height: auto;
}

.op-cursor-pointer {
  cursor: pointer;
}

.op-pointer-events-none {
  pointer-events: none;
}

.op-font-size-48 {
  font-size: common.toRem(48) !important;
}

.op-outline-none {
  outline: none;
}

/* END - Terrible attempt at standardization that needs to be removed */

.partner-center-section {
  font-size: common.toRem(18) !important;
  box-shadow: none !important;
  border: none !important;
  padding: common.toRem(22) !important;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
}

.appDetailsText,
.appDetailsText p,
.appDetailsText ul li {
  font-size: common.toRem(16);
}