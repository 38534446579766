@use '../../../scss/common';

.op-page {
  padding: common.toRem(16);
  padding-left: common.toRem(48);
  padding-right: common.toRem(48);
  overflow: auto;
  height: 100%;
  background-color: var(--colorBgContainer);

  &__header {
    margin-bottom: common.toRem(16);
    display: flex;
    justify-content: space-between;
  }

  &__title-row {
    display: flex;
  }

  &__title {
    padding-right: common.toRem(4);
  }

  &__subtitle {
    font-size: common.toRem(14);
    color: var(--colorTextLabel);
  }
}