// login.scss
@use 'scss/common';

.auth-wrapper {
    display: flex;
    align-items: stretch;
}

.auth-wrapper__left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-bg-container);
    color: var(--color-text);
}

.auth-wrapper__right {
    flex: 1;
    background-image: url('../../images/receptionist01.jpg');
    min-height: 100vh;
    background-size: cover;
    background-position: center;
}

.auth-wrapper__left-logo {
    padding-bottom: 40px;
}

.button {
    width: 100%;
}

.login {
    width: 100%;
}