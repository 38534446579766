//@use 'assets/scss/common/_colors';
@use '../common/_variables';

:root {
  // SEED TOKENS
  --colorError: #de1111;
  --colorInfo: #8d9399;
  --colorLink: #006ed7;
  --colorPrimary: #006ed7;
  --colorSuccess: #20c62f;
  --colorTextBase: #121826;
  --colorWarning: #eaa301;

  // MAP: TOKENS
  --colorBgContainer: #ffffff;
  --colorBgElevated: #ffffff;
  --colorBgLayout: #ffffff;
  --colorBgMask: rgba(18, 24, 38, 0.45);
  --colorBgSpotlight: #363b47;
  --colorBorder: #c2c7cc;
  --colorBorderSecondary: #ebeef0;
  --colorErrorActive: #b8060b;
  --colorErrorBg: #ffeae6;
  --colorErrorBgHover: #ffc1b8;
  --colorErrorBorder: #ff9a8f;
  --colorErrorBorderHover: #f76d63;
  --colorErrorHover: #eb3e38;
  --colorErrorText: #de1111;
  --colorErrorTextActive: #b8060b;
  --colorErrorTextHover: #eb3e38;
  --colorFill: #dbdcde;
  --colorFillSecondary: #f1f1f2;
  --colorFillTertiary: #f6f6f6;
  --colorFillQuaternary: #fafafb;
  --colorInfoActive: #646b73;
  --colorInfoBg: #f2f6fb;
  --colorInfoBgHover: #e1e5eb;
  --colorInfoBorder: #d5d8dc;
  --colorInfoBorderHover: #a8aeb3;
  --colorInfoHover: #a8aeb3;
  --colorInfoText: #8d9399;
  --colorInfoTextActive: #646b73;
  --colorInfoTextHover: #9ca1a6;
  --colorLinkActive: #005cb5;
  --colorLinkHover: #0065c6;
  --colorPrimaryActive: #005cb5;
  --colorPrimaryBg: #e6f7ff;
  --colorPrimaryBgHover: #a3dfff;
  --colorPrimaryBorder: #79cafc;
  --colorPrimaryBorderHover: #4dacf0;
  --colorPrimaryHover: #0065c6;
  --colorPrimaryText: #006ed7;
  --colorPrimaryTextActive: #0055b0;
  --colorPrimaryTextHover: #248de3;
  --colorSuccessActive: #12a122;
  --colorSuccessBg: #f1fff0;
  --colorSuccessBgHover: #cafac8;
  --colorSuccessBorder: #99ed98;
  --colorSuccessBorderHover: #6ce06e;
  --colorSuccessHover: #6ce06e;
  --colorSuccessText: #13761c;
  --colorSuccessTextActive: #12a122;
  --colorSuccessTextHover: #44d44b;
  --colorText: #121826;
  --colorTextSecondary: #656972;
  --colorTextTertiary: #94979d;
  --colorTextQuaternary: #c4c5c9;
  --colorWarningActive: #c48300;
  --colorWarningBg: #fffce6;
  --colorWarningBgHover: #fff0a3;
  --colorWarningBorder: #ffe47a;
  --colorWarningBorderHover: #ffd752;
  --colorWarningHover: #ffd752;
  --colorWarningText: #eaa301;
  --colorWarningTextActive: #c48300;
  --colorWarningTextHover: #f7c028;
  --colorWhite: #ffffff;

  // ALIAS: TOKENS (just for documentation, likely not needed for theming)	;
  --colorBgContainerDisabled: rgba(0, 0, 0, 0.04);
  --colorBgTextActive: rgba(0, 0, 0, 0.15);
  --colorBgTextHover: rgba(0, 0, 0, 0.06);
  --colorBorderBg: #ffffff;
  --colorErrorOutline: rgba(255, 38, 5, 0.06);
  --colorFillAlter: rgba(0, 0, 0, 0.02);
  --colorFillContent: rgba(0, 0, 0, 0.06);
  --colorFillContentHover: rgba(0, 0, 0, 0.15);
  --colorHighlight: #ff4d4f;
  --colorIcon: rgba(18, 24, 38, 0.45);
  --colorIconHover: rgba(18, 24, 38, 0.88);
  --colorSplit: #ebecee;
  --colorTextDescription: rgba(18, 24, 38, 0.45);
  --colorTextDisabled: rgb(0, 0, 0);
  --colorTextHeading: #121826;
  --colorTextLabel: rgba(18, 24, 38, 0.65);
  --colorTextLightSolid: #ffffff;
  --colorTextPlaceholder: #c2c7cc;
  --colorWarningOutline: rgba(255, 215, 5, 0.1);
  --controlItemBgActive: #e6f7ff;
  --controlItemBgActiveDisabled: rgba(0, 0, 0, 0.15);
  --controlItemBgActiveHover: #bae0ff;
  --controlItemBgHover: rgba(18, 24, 38, 0.04);
  --controlOutline: rgba(5, 175, 255, 0.1);
  --controlTmpOutline: rgba(0, 0, 0, 0.2);

  // ALARM COLORS
  --colorAlarmHighBg: #a80c0c;
  --colorAlarmHighText: #ffffff;
  --colorAlarmMediumBg: #f7dea4;
  --colorAlarmMediumText: #7a5500;
  --colorAlarmLowBg: #d6dadd;
  --colorAlarmLowText: #121826;

  // HEADER COLORS
  --colorHeaderBg: #f2f4f7;
  --colorHeaderButtonBg: #e0e4e7;
  --colorHeaderButtonText: #121826;
  --colorHeaderButtonBgHover: #d7dbde;
  --colorHeaderButtonBgActive: #d7dbde;

  // OTHER COLORS
  --colorUserMenuTextSecondary: #666666;
  --colorNavSubMenuBg: #ebeef0;
  --colorSectionBorder: #eff0f1;
  --colorBoxShadow: #00000014;

  // NON-COLOR STUFF
  --baseFontSize: 14px;
  --baseSpacing: 8px;

  --borderRadius: #{variables.$border-radius};
  --borderRadius--rounded: #{variables.$border-radius--rounded};

  --styleBoxShadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  --styleBoxShadowHover: 0 1px 16px 0 rgba(0, 0, 0, 0.16);
}

:root.dark {
  // SEED TOKENS
  --colorError: #ff1f1f;
  --colorInfo: #7b8085;
  --colorLink: #1f93ff;
  --colorPrimary: #006ed7;
  --colorSuccess: #20c62f;
  --colorTextBase: #ffffff;
  --colorWarning: #eaa301;

  // MAP TOKENS;
  --colorBgContainer: #121826;
  --colorBgElevated: #181f32;
  --colorBgLayout: #121826;
  --colorBgMask: rgba(0, 0, 0, 0.45);
  --colorBgSpotlight: #5c6a77;
  --colorBorder: #5c6a77;
  --colorBorderSecondary: #2d3642;
  --colorErrorActive: #ad1b1b;
  --colorErrorBg: #2c1213;
  --colorErrorBgHover: #451315;
  --colorErrorBorder: #5b1717;
  --colorErrorBorderHover: #7e1919;
  --colorErrorHover: #e84842;
  --colorErrorText: #ff1f1f;
  --colorErrorTextActive: #ad1b1b;
  --colorErrorTextHover: #e84842;
  --colorFill: #3d424d;
  --colorFillSecondary: #2e3440;
  --colorFillTertiary: #252a37;
  --colorFillQuaternary: #1b212f;
  --colorInfoActive: #63676a;
  --colorInfoBg: #1a1b1d;
  --colorInfoBgHover: #282a2c;
  --colorInfoBorder: #383a3c;
  --colorInfoBorderHover: #4a4d50;
  --colorInfoHover: #4a4d50;
  --colorInfoText: #7b8085;
  --colorInfoTextActive: #63676a;
  --colorInfoTextHover: #8e9397;
  --colorLinkActive: #78beff;
  --colorLinkHover: #4ba8ff;
  --colorPrimaryActive: #005cb5;
  --colorPrimaryBg: #111a26;
  --colorPrimaryBgHover: #0f243b;
  --colorPrimaryBorder: #0e2f4f;
  --colorPrimaryBorderHover: #0b3d6c;
  --colorPrimaryHover: #0065c6;
  --colorPrimaryText: #1f93ff;
  --colorPrimaryTextActive: #074f93;
  --colorPrimaryTextHover: #2281ce;
  --colorSuccessActive: #1c8826;
  --colorSuccessBg: #122315;
  --colorSuccessBgHover: #143718;
  --colorSuccessBorder: #18491c;
  --colorSuccessBorderHover: #196420;
  --colorSuccessHover: #196420;
  --colorSuccessText: #78dc81;
  --colorSuccessTextActive: #1c8826;
  --colorSuccessTextHover: #3fc146;
  --colorText: #dbdcde;
  --colorTextSecondary: #acaeb3;
  --colorTextTertiary: #7d8088;
  --colorTextQuaternary: #4d525c;
  --colorWarningActive: #9f7108;
  --colorWarningBg: #292011;
  --colorWarningBgHover: #40300f;
  --colorWarningBorder: #40300f;
  --colorWarningBorderHover: #74540b;
  --colorWarningHover: #74540b;
  --colorWarningText: #ca8e04;
  --colorWarningTextActive: #9f7108;
  --colorWarningTextHover: #e0af26;
  --colorWhite: #ffffff;

  // ALIAS TOKENS (just for documentation, likely not needed for theming);
  --colorBgContainerDisabled: rgba(255, 255, 255, 0.8);
  // --colorBgTextActive:  	;
  // --colorBgTextHover:  	;
  // --colorBorderBg:  	;
  // --colorErrorOutline:  	;
  // --colorFillAlter:  	;
  // --colorFillContent:  	;
  // --colorFillContentHover:  	;
  // --colorHighlight:  	;
  // --colorIcon:  	;
  // --colorIconHover:  	;
  // --colorSplit:  	;
  // --colorTextDescription:  	;
  --colorTextDisabled: rgb(255, 255, 255);
  --colorTextHeading: #ffffff;
  --colorTextLabel: rgba(255, 255, 255, 0.65);
  --colorTextLightSolid: #ffffff;
  --colorTextPlaceholder: rgba(255, 255, 255, 0.25);
  // --colorWarningOutline:  	;
  // --controlItemBgActive:  	;
  // --controlItemBgActiveDisabled:  	;
  // --controlItemBgActiveHover:  	;
  --controlItemBgHover: rgba(255, 255, 255, 0.08);
  // --controlOutline:  	;
  --controlTmpOutline: rgba(255, 255, 255, 0.2);

  // ALARM COLORS
  --colorAlarmHighBg: #a80c0c;
  --colorAlarmHighText: #ffffff;
  --colorAlarmMediumBg: #57441a;
  --colorAlarmMediumText: #fcf4e1;
  --colorAlarmLowBg: #3e4652;
  --colorAlarmLowText: #ffffff;

  // HEADER COLORS
  --colorHeaderBg: #181f32;
  --colorHeaderButtonBg: #31394b;
  --colorHeaderButtonText: #ffffff;
  --colorHeaderButtonBgHover: #3a4254;
  --colorHeaderButtonBgActive: #3a4254;

  // OTHER COLORS
  --colorUserMenuTextSecondary: #91929b;
  --colorNavSubMenuBg: #21283a;
  --colorSectionBorder: #171d2d;
  --colorBoxShadow: #00000029;

  // NON-COLOR STUFF
  --styleBoxShadow: 0 1px 4px rgba(0, 0, 0, 0.4);
  --styleBoxShadowHover: 0 1px 16px 0 rgba(0, 0, 0, 0.64);
}